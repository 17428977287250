$grey: grey;
$green: rgb(0, 187, 0);

.row {
  display: flex;
  height: calc(100vh - 100px);
  padding-top: 10px;

  .left_col,
  .right_col {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;

    .header {
      text-align: center;
      font-weight: 600;
      text-transform: uppercase;
      color: white;
      font-size: 2.7vw;
      line-height: 1.5;
    }

    .list_orders {
      text-align: center;
      font-size: 1.8vw;
      line-height: 2;
      font-weight: 500;
      max-height: calc(100vh - 60px - 60px);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      text-transform: capitalize;
      overflow-x: hidden;

      div{
        display: flex;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &.left_col {
      .header {
        background-color: $grey;
      }

      .list_orders {
        color: $grey;
      }
    }

    &.right_col {
      .header {
        background-color: $green;
      }

      .list_orders {
        color: $green;
      }
    }
  }
}

.logo {
  position: fixed;
  bottom: 20px;
  left: 20px;
  height: 40px;
}

.href_block{
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;

  > div{
    display: flex;
    align-items: center;
    margin-left: 40px;

    svg{
      height: 40px;
      width: auto;
      margin-right: 15px;
    }
  }

  
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaLight.woff2);
  font-weight: 100;
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaLight.woff2);
  font-weight: 200;
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaRegular.woff2);
  font-weight: 300;
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaRegular.woff2);
  font-weight: 400;
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaBold.woff2);
  font-weight: 500;
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaBold.woff2);
  font-weight: 600;
}

@font-face {
  font-family: "Fujiya";
  src: url(./fonts/Fujiya/FujiyaBold.woff2);
  font-weight: 700;
}

* {
  font-family: "Fujiya";
}
